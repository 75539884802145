import 'styles/login.css';
import { useNavigate } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import Users from "services/Users";

function buttonClick() {
    window.location.href = `${process.env.REACT_APP_AUTH_API_URL}${process.env.REACT_APP_ELDORADO_FRONT_URL}`;
}

export default function Login() {
    const navigate = useNavigate();
    const [loginError, setLoginError] = useState(false);
    const [loginNeeded, setLoginNeeded] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            let search = window.location.search;
            let params = new URLSearchParams(search);
            let token = params.get("token") || localStorage.getItem("token");

            if (token) {
                const ms_user = jwtDecode(token);
                try {
                    if (ms_user && ms_user.preferred_username) {
                        const api_user = await Users.getByEmail(ms_user?.preferred_username, token);
                        
                        if (api_user) {
                            localStorage.setItem("token", token);
                            localStorage.setItem("email", ms_user?.preferred_username);
                            localStorage.setItem("name", ms_user?.name);
                            localStorage.setItem("profile", api_user?.profile);
                            localStorage.setItem("profile_canEdit", api_user?.api_user_profiles?.canEdit);
                            localStorage.setItem("profile_canValidate", api_user?.api_user_profiles?.canValidate);
                            localStorage.setItem("profile_canManage", api_user?.api_user_profiles?.canManage);
                            localStorage.setItem("profile_canImport", api_user?.api_user_profiles?.canImport);

                            window.history.replaceState(null, '', '/');
                            navigate('/mensalidades');
                        } else {
                            setLoginError(true);
                        }
                    } else {
                        setLoginError(true);
                    }
                } catch (e) {
                    setLoginError(true);
                }
            } else {
                setLoginNeeded(true);
            }
        }
        fetchData();
        window.history.replaceState(null, '', '/');
    }, [navigate])

    if (loginNeeded || loginError) {
        return (
            <div className="main">
                <div className="wrapper">
                    <img alt="FMU FIAM FAAM" src="/assets/logo-fmu-fiaam.png" />
                    <button className="bsk-btn" onClick={buttonClick.bind(this)}>
                        <object type="image/svg+xml" data="/assets/ms-icon.svg" className="x-icon">MS Logo</object>
                        Entrar com Microsoft
                    </button>
                    {loginError && <p className="no-access">Usuário não possui permissão de acesso.</p>}
                </div>
            </div>
        );
    } else {
        return (<div className="main"></div>);
    }
}