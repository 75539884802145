
import {
  Flex,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  Text,
  useColorModeValue,
  Heading,
  Box,
  Input,
  IconButton,
  useToast
} from "@chakra-ui/react";

import { FaEye, FaBan } from "react-icons/fa";
import { SearchIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { toastError, toastInfo, toastSuccess } from "constants"
import { pathOr, isEmpty } from "ramda";
import ResponsivePagination from "react-responsive-pagination";

import "styles/pagination.css";
import StatusButton from "./StatusButton"
import Fees from "services/Fees";
import { isNumber } from "utils";
import JustificationModal from "./JustificationModal";
import DeleteDocumentModal from "../Common/DeleteDocumentModal";
import SwitchButton from "../Common/SwitchButton";

const ValidationForm = ({ loadingHandler }) => {
  const toast = useToast();
  const tableheaderbgc = useColorModeValue("gray.100", "#171923");
  const redColorScheme = useColorModeValue("#e53e3e", "#feb2b2");
  const invertedColorScheme = useColorModeValue("#eeeff0", "#1a202c");

  const [monthlyFees, setMonthlyFees] = useState({ total: 0, data: [] });
  const [showTable, setShowTable] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [studentInfo, setStudentInfo] = useState(null);
  const [selectedPage, setSelectedPage] = useState(1);
  const [showStudentInfo, setShowStudentInfo] = useState(false);
  const [selectedFee, setSelectedFee] = useState(null);
  const [selectedValidationStatus, setSelectedValidationStatus] = useState(null);
  const [isJustificationModalOpen, setIsJustificationModalOpen] = useState(false);
  const [isDeleteDocumentModalOpen, setIsDeleteDocumentModalOpen] = useState(false);
  const [deleteDocumentModalSource, setDeleteDocumentModalSource] = useState("");
  const [pendingChecked, setPendingChecked] = useState(true);

  const handlePageChange = async (page) => {
    setSelectedPage(page);
    search(page, pendingChecked);
  }

  const handlePendingChecked = async (checked) => {
    setPendingChecked(checked);
    search(selectedPage, checked);
  };

  const handleSearch = async () => {
    await search(selectedPage, pendingChecked);
  };

  const search = async (_selectedPage, _pendingChecked) => {
    try {
      loadingHandler(true);
      let feeData;
      let showStudentInfo;

      if (searchQuery && !isEmpty(searchQuery)) {
        showStudentInfo = isNumber(searchQuery);
        feeData = showStudentInfo ?
          await Fees.getPendingByRA(searchQuery, !_pendingChecked, _selectedPage - 1) :
          await Fees.getPendingByName(searchQuery, !_pendingChecked, _selectedPage - 1);
      } else {
        feeData = await Fees.getPending(!_pendingChecked, _selectedPage - 1);
        showStudentInfo = false;
      }

      setShowStudentInfo(showStudentInfo);

      if (!feeData || feeData?.total === 0) {
        setShowTable(false);
        setStudentInfo(null);
        toast(toastInfo.notFound);
      } else {
        setMonthlyFees(feeData);
        if (!showStudentInfo) {
          setStudentInfo(null);
        } else {
          setStudentInfo(`${feeData?.data[0]?.RA} - ${feeData?.data[0]?.NOME}`);
        }
        setShowTable(true);
      }
    } catch (error) {
      console.error("Erro ao buscar dados do aluno:", error);
      toast(toastError.notFound);
    }
    finally {
      loadingHandler(false);
    }
  }

  // Abre o modal de justificativa
  const handleOpenJustificationModal = (fee) => {
    setSelectedFee(fee);
    setIsJustificationModalOpen(true);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.target.blur();
    }
  }

  const validateButtonStatus = (fee) => {
    const cantValidate = localStorage.getItem("profile_canValidate") !== "true";

    if (fee.validated_by === 'conciliado') {
      return { status: 3, disabled: true };
    } else if (fee.updated_by !== null && fee.financ_status === null) {
      if (fee.SYS_STATUS === false) {
        return { status: null, disabled: cantValidate };
      } else {
        return { status: 2, disabled: true };
      }
    }
    else if (fee.updated_by === null && fee.financ_status === null) {
      return { status: 2, disabled: true };
    }
    else {
      return { status: fee.financ_status ? 1 : 0, disabled: cantValidate };
    }
  };

  const handleUpdateValidation = async (fee, newStatus) => {
    if (newStatus === false && fee.upload_file_id) {
      handleOpenDeleteDocumentModal("updateValidation", fee, newStatus);
    } else {
      await updateValidation(fee, newStatus);
    }
  };

  // Abre o modal de exclusão de documento
  const handleOpenDeleteDocumentModal = (source, fee, validation) => {
    setSelectedFee(fee);
    setSelectedValidationStatus(validation);
    setDeleteDocumentModalSource(source);
    setIsDeleteDocumentModalOpen(true);
  };

  // Salva a validação financeira
  const updateValidation = async (fee, newStatus) => {
    try {
      setIsJustificationModalOpen(false);
      loadingHandler(true);
      fee.financ_status = newStatus;

      let updatedFee = {
        financ_status: fee.financ_status,
        financ_obs: fee.financ_obs,
      };
      updatedFee = await Fees.updateData(fee.ID, updatedFee);

      if (updatedFee) {
        setMonthlyFees({
          total: monthlyFees.total,
          data: pathOr([], ["data"], monthlyFees).map((item) =>
            item.ID === updatedFee.ID ? updatedFee : item
          ),
        });
      }
      toast(toastSuccess.updateFeeValidation(updatedFee));
    } catch (error) {
      toast(toastError.updateValidation(error));
      console.error(
        "Erro ao atualizar financ_status:",
        error.response?.data || error.message
      );
    } finally {
      loadingHandler(false);
    }
  };

  // Salva a justificativa da reprova da validação financeira
  const handleSaveJustification = async (justification) => {
    selectedFee.financ_obs = justification;
    handleUpdateValidation(selectedFee, false);
  };

  // Permite excluir o documento
  const handleDeleteDocument = async () => {
    setIsDeleteDocumentModalOpen(false);
    if (deleteDocumentModalSource === "toggleStatus") {
      await toogleStatus(selectedFee);
    }
    if (deleteDocumentModalSource === "updateValidation") {
      await updateValidation(selectedFee, selectedValidationStatus);
    }
  };

  // Altera status da mensalidade
  const toogleStatus = async (fee) => {
    try {
      fee.SYS_STATUS = !fee.SYS_STATUS;

      let updatedFee = {
        SYS_STATUS: fee.SYS_STATUS,
      };

      updatedFee = await Fees.updateData(fee.ID, updatedFee);

      if (updatedFee) {
        setMonthlyFees({
          total: monthlyFees.total,
          data: pathOr([], ["data"], monthlyFees).map((item) =>
            item.ID === updatedFee.ID ? updatedFee : item
          ),
        });
      }
      toast(toastSuccess.updateFeeStatus(updatedFee));
    } catch (error) {
      toast(toastError.updateMonthlyFee(error));
      console.error(
        "Erro ao atualizar SYS_STATUS:",
        error.response?.data || error.message
      );
    }
  };

  const handleGetFile = async (fileId) => {
    try {
      const fileUrl = await Fees.getDocument(fileId);
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error(error);
    }
  };

  const paginationCalulate = () => {
    const totalItems = monthlyFees.total;
    const itemsPerPage = 10;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    return totalPages;
  };

  // Inicialização
  useEffect(() => {
    search(selectedPage, pendingChecked);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Heading
        as="h1"
        size="lg"
        mb="6"
        textAlign="center"
        bg={redColorScheme}
        bgClip="text"
      >
        Validação Financeira
      </Heading>

      <form autoComplete="on" onSubmit={(e) => { e.preventDefault(); handleSearch(selectedPage, pendingChecked); }}>
        <Flex mb="4" justify="center" px={{ base: 6, lg: 0 }}>
          <Flex justify="center" width={{ base: "100%", lg: "50%" }}>
            <Input
              name="query"
              placeholder="Pesquisar por RA ou nome"
              mr="2"
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyUp={handleKeyUp}
            />

            <IconButton
              icon={<SearchIcon color={invertedColorScheme} />}
              bg={redColorScheme}
              type="submit"
            />
          </Flex>
        </Flex>
        <Flex mb="2" justify="center" px={{ base: 6, lg: 0 }}>
          <SwitchButton
            id="underAnalysisOnly"
            isOn={pendingChecked}
            handleToggle={() => handlePendingChecked(!pendingChecked)}
            label='Somente "Em análise"'
          />
        </Flex>
      </form>

      {showTable && (
        <>
          <Flex width={"100%"}>
            <Flex justify="left" pl="6" width={"50%"}>
              {showStudentInfo && (
                <Text align={"left"} alignSelf={"flex-end"} fontWeight="bold" fontSize="sm">
                  {studentInfo}
                </Text>)
              }
            </Flex>
            <Flex justify="right" alignSelf={"flex-end"} pr="6" width={"50%"}>
              <Text align={"right"} fontSize="sm">
                Total de registros: {pathOr(0, ["total"], monthlyFees)}
              </Text>
            </Flex>
          </Flex>
          <Flex gap="4" mt="4" justify="center" direction={"column"}>
            <TableContainer width="100%" overflowX="auto">
              <Table>
                <Thead bg={tableheaderbgc}>
                  <Tr>
                    {
                      !showStudentInfo && (
                        <>
                          <Th>RA</Th>
                          <Th>Nome</Th>
                        </>
                      )
                    }
                    <Th>Tipo</Th>
                    <Th>Curso</Th>
                    <Th>Tipo Pagamento</Th>
                    <Th>Valor</Th>
                    <Th>Parcela</Th>
                    <Th>Vencimento</Th>
                    <Th textAlign="center">Comprovante</Th>
                    <Th textAlign="center">Validar</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {monthlyFees.data.map((fee) => (
                    <Tr key={fee.ID}>
                      {
                        !showStudentInfo && (
                          <>
                            <Td>{fee.RA || "N/A"}</Td>
                            <Td>{fee.NOME || "N/A"}</Td>
                          </>
                        )
                      }
                      <Td>{fee.TIPO_DEB}</Td>
                      <Td>{fee.CURSO}</Td>
                      <Td>{fee.TIPO_PAG}</Td>
                      <Td>{fee.VALOR}</Td>
                      <Td>{fee.PARC}</Td>
                      <Td>{fee.VENCTO}</Td>
                      <Td textAlign="center">
                        {fee.upload_file_id && (
                          <Button
                            width={"170px"}
                            size="sm"
                            colorScheme="blue"
                            onClick={() => handleGetFile(fee.upload_file_id)}
                            leftIcon={<FaEye />}
                          >
                            Ver comprovante
                          </Button>
                        )}
                        {!fee.upload_file_id && (
                          <Button
                            width={"170px"}
                            size="sm"
                            colorScheme="blue"
                            leftIcon={<FaBan />}
                            isDisabled={true}
                          >
                            Não enviado
                          </Button>
                        )}
                      </Td>
                      <Td textAlign="center">
                        <StatusButton
                          fee={fee}
                          status={validateButtonStatus(fee)}
                          onUpdateValidation={handleUpdateValidation}
                          onOpenJustification={handleOpenJustificationModal}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
          {paginationCalulate() > 1 && (
            <Flex mt="8" mb="4" justify="center">
              <ResponsivePagination
                current={selectedPage}
                total={paginationCalulate()}
                onPageChange={handlePageChange}
                maxWidth={500}
              />
            </Flex>
          )}
        </>
      )}

      <JustificationModal
        isOpen={isJustificationModalOpen}
        onClose={() => setIsJustificationModalOpen(false)} // Fecha o modal sem salvar
        onSave={handleSaveJustification} // Salva a justificativa e atualiza
      />

      <DeleteDocumentModal
        isOpen={isDeleteDocumentModalOpen}
        onClose={() => setIsDeleteDocumentModalOpen(false)}
        onConfirm={handleDeleteDocument}
        source={deleteDocumentModalSource}
      />
    </Box>
  );
};

export default ValidationForm;
