import { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  Input,
  Text,
  Box,
  Button,
  Heading,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";

import { FaCheckCircle, FaTimesCircle, FaClock, FaDownload, FaUpload, FaFileCsv } from "react-icons/fa";
import { toastError, toastSuccess } from "constants"
import dateFormat from "dateformat";
import Import from "services/Import";
import ImportResultModal from "./ImportResultModal";

const ImportForm = ({ loadingHandler }) => {
  const toast = useToast();
  const tableheaderbgc = useColorModeValue("gray.100", "#171923");
  const redColorScheme = useColorModeValue("#e53e3e", "#feb2b2");
  const greenColorScheme = useColorModeValue("#38a169", "#9ae6b4");
  const grayColorScheme = useColorModeValue("#718096", "#474c56");
  const blueColorScheme = useColorModeValue("#3182ce", "#90cdf4");

  const [templates, setTemplates] = useState([]);
  const [importTypes, setImportTypes] = useState([]);
  const [status, setStatus] = useState({});
  const [isImportResultModalModalOpen, setIsImportResultModalOpen] = useState(false);
  const [importResult, setImportResult] = useState(null);

  const loadImportInfo = async () => {
    try {
      loadingHandler(true);
      let templates = await Import.getTemplates();
      let importTypes = [];
      for (const target in templates) {
        const template = templates[target];
        if (template.lastImportAt) {
          const importedAt = dateFormat(template.lastImportAt, "dd/mm/yyyy 'às' HH'h'MM");
          status[target] = { text: `Última importação: ${importedAt}, por ${template.lastImportBy}.`, status: "success" };
        } else {
          status[target] = { text: "Não importado", status: "pending" };
        }
        importTypes.push({ id: target, name: template.alias });
      }
      setTemplates(templates);
      setImportTypes(importTypes);
    } catch (error) {
      console.error("Erro ao buscar dados da importação:", error);
      toast(toastError.importInfoError(error));
    }
    finally {
      loadingHandler(false);
    }
  }

  const handleFileUpload = async (type, event) => {
    const file = event.target.files[0];
    if (file) {
      if (!file.name.endsWith(".csv")) {
        status[type] = { text: "Erro: Formato inválido", status: "error" };
        return;
      }

      try {
        loadingHandler(true);

        const formData = new FormData();
        formData.append("file", file);
        const response = await Import.import(formData, type);

        if (response) {
          const importedBy = response.importedBy;
          const importedAt = dateFormat(response.importedAt, "dd/mm/yyyy 'às' HH'h'MM");

          setStatus({ ...status, [type]: { text: `Última importação: ${importedAt}, por ${importedBy}.`, status: "success" } });

          setImportResult(response);
          setIsImportResultModalOpen(true);

          toast(toastSuccess.importSuccess);
        } else {
          console.error("Erro ao importar arquivo.");
          toast(toastError.importError("Erro ao importar arquivo."));
        }
      } catch (error) {
        console.error("Erro ao importar arquivo:", error);
        if (error?.response?.data?.errors?.length > 0) {
          setImportResult(error.response.data);
          setIsImportResultModalOpen(true);
        } else {
          toast(toastError.importError(error));
        }
      }
      finally {
        loadingHandler(false);
      }
    }
  };

  const downloadTemplate = async (type) => {
    try {
      loadingHandler(true);

      const fields = templates[type].fields;
      const fieldNames = fields.map(f => f.alias);
      const csvContent = fieldNames.join(";");

      if (csvContent) {
        const blob = new Blob(["\ufeff" + csvContent], { encoding: "UTF-8", type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${type}_template.csv`;
        link.click();
      }
    } catch (error) {
      console.error("Erro na criação do template: ", error);
      toast(toastError.templateError(error));
    }
    finally {
      loadingHandler(false);
    }
  };

  const downloadLastImport = async (type) => {
    try {
      loadingHandler(true);
      let csvContent = await Import.getLast(type);
      if (csvContent) {
        const blob = new Blob(["\ufeff" + csvContent], { encoding: "UTF-8", type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${type}_last_import.csv`;
        link.click();
      }
    } catch (error) {
      console.error("Erro ao buscar dados da importação:", error);
      toast(toastError.importInfoError(error));
    }
    finally {
      loadingHandler(false);
    }
  };

  // Inicialização
  useEffect(() => {
    loadImportInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Heading
        as="h1"
        size="lg"
        mb="6"
        textAlign="center"
        bg={redColorScheme}
        bgClip="text"
      >
        Importações
      </Heading>
      <Flex gap="4" mt="4" justify="center" direction={"column"}>
        <TableContainer width={"100%"} overflowX="auto">
          <Table variant="simple">
            <Thead bg={tableheaderbgc}>
              <Tr>
                <Th>Importação</Th>
                <Th textAlign="center">Template</Th>
                <Th textAlign="center">Arquivo</Th>
                <Th>Status</Th>
                <Th textAlign="center">Última importação</Th>
              </Tr>
            </Thead>
            <Tbody>
              {importTypes.map(({ id, name }) => (
                <Tr key={id}>
                  <Td>
                    <Flex align="center" gap={2}>
                      <FaFileCsv size={24} color={blueColorScheme} /> {name}
                    </Flex>
                  </Td>
                  <Td>
                    <Flex align="center" justify="center">
                      <Button
                        width={"100px"}
                        size="sm"
                        colorScheme={"gray"}
                        leftIcon={<FaDownload />}
                        onClick={() => downloadTemplate(id)}
                      >
                        Baixar
                      </Button>
                    </Flex>
                  </Td>
                  <Td>
                    <Flex align="center" justify="center">
                      <Button
                        minWidth={"170px"}
                        size="sm"
                        colorScheme="blue"
                        leftIcon={<FaUpload />}
                        onClick={() =>
                          document.getElementById(`file-upload-${id}`).click()
                        }
                      >
                        Enviar arquivo
                      </Button>
                      <Input
                        type="file"
                        id={`file-upload-${id}`}
                        accept=".csv"
                        required
                        onChange={(e) => handleFileUpload(id, e)}
                        display="none"
                      />
                    </Flex>
                  </Td>
                  <Td>
                    <Flex align="center">
                      {status[id]?.status === "success" ? (
                        <Text fontSize="md" lineHeight="12px" color={greenColorScheme}><i style={{ float: "left", paddingRight: "4px" }}><FaCheckCircle /></i>{status[id]?.text}</Text>
                      ) : status[id]?.status === "error" ? (
                        <Text fontSize="md" lineHeight="12px" color={redColorScheme}><i style={{ float: "left", paddingRight: "4px" }}><FaTimesCircle /></i>{status[id]?.text}</Text>
                      ) : (
                        <Text fontSize="md" lineHeight="12px" color={grayColorScheme}><i style={{ float: "left", paddingRight: "4px" }}><FaClock /></i>{status[id]?.text}</Text>
                      )}
                    </Flex>
                  </Td>
                  <Td>
                    <Flex align="center" justify="center">
                      <Button
                        disabled={status[id]?.status !== "success"}
                        width={"100px"}
                        size="sm"
                        colorScheme={"gray"}
                        leftIcon={<FaDownload />}
                        onClick={() => downloadLastImport(id)}
                      >
                        Baixar
                      </Button>
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
      <ImportResultModal
        isOpen={isImportResultModalModalOpen}
        onClose={() => setIsImportResultModalOpen(false)}
        importResult={importResult}
      />
    </Box>
  );
}

export default ImportForm;