import React from 'react';
import "styles/switch-button.css";
import { useState } from "react";

const Switch = ({ id, isOn, handleToggle, label }) => {
    const [state, setState] = useState(isOn);
    const toggle = () => {
        setState(!state);
        handleToggle();
    }

    return (
        <div className='react-switch'>
            <input
                defaultChecked={isOn}
                onChange={toggle}
                className="react-switch-checkbox"
                id={id}
                type="checkbox"
            />
            <label
                style={{ background: state && 'var(--switch-bg-color)', float: 'left' }}
                className="react-switch-bg"
                htmlFor={id}
            >
                <span className={`react-switch-button`} />
            </label>
            <label htmlFor={id} className='react-switch-label'>{label}</label>
        </div>
    );
};

export default Switch;