import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const theme = extendTheme({
  styles: {
    global: (props) => ({
      ":root": {
        "--red-button": mode("var(--chakra-colors-red-500)", "var(--chakra-colors-red-200)")(props),
        "--green-button": mode("var(--chakra-colors-green-500)", "var(--chakra-colors-green-200)")(props),
        "--bg-color-alpha": mode("rgba(255,255,255,0.9)", "rgba(26,32,44,0.9)")(props),
        "--switch-bg-color": mode("var(--chakra-colors-red-500)", "var(--chakra-colors-red-200)")(props),
        "--switch-bg-color-disabled": mode("var(--chakra-colors-gray-300)", "#51555f")(props),
        "--text-disabled": mode("#718096", "#5f6673")(props),
        "--text-enabled": mode("#1a202c", "#eeeff0")(props),
        "--text-inverted": mode("#eeeff0", "#1a202c")(props)
      }
    })
  }
});

export default theme;
