import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Text,
} from "@chakra-ui/react";

const DocumentRequiredModal = ({ isOpen, onClose, newStatus }) => {
  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Comprovante obrigatório
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>Para definir esta mensalidade como '{newStatus}', primeiro é necessário enviar um comprovante.</Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose} size={"sm"}>
              OK
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default DocumentRequiredModal;
