import {
  Box,
  Flex,
  Input,
  useToast,
  IconButton,
  Heading,
  Button,
  Text,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";

import {
  FaUpload,
  FaEye,
} from "react-icons/fa";

import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { formatToBackendDate } from "utils";
import { allowedExtensions } from "./UploadModal";
import { pathOr, isEmpty } from "ramda";
import { toastError, toastInfo, toastSuccess } from "constants"
import { SearchIcon } from "@chakra-ui/icons";
import ResponsivePagination from "react-responsive-pagination";

import "styles/pagination.css";
import StatusLabel from "./StatusLabel"
import Fees from "services/Fees";
import EditModal from "./EditModal"
import UploadModal from "./UploadModal";
import DeleteDocumentModal from "../Common/DeleteDocumentModal";
import DocumentRequiredModal from "./DocumentRequiredModal";
import ValueModal from "./ValueModal";
import { isNumber } from "utils";
import StatusButton from "./StatusButton"

const FeesForm = ({ loadingHandler }) => {
  const toast = useToast();
  const tableheaderbgc = useColorModeValue("gray.100", "#171923");
  const redColorScheme = useColorModeValue("#e53e3e", "#feb2b2");
  const invertedColorScheme = useColorModeValue("#eeeff0", "#1a202c");

  const [showEditModal, setShowEditModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [monthlyFee, setMonthlyFee] = useState({});
  const [showTable, setShowTable] = useState(false);
  const [monthlyFees, setMonthlyFees] = useState({ total: 0, data: [] });
  const [searchQuery, setSearchQuery] = useState(null);
  const [studentInfo, setStudentInfo] = useState(null);
  const [selectedPage, setSelectedPage] = useState(1);
  const [file, setFile] = useState(null);
  const [selectedFee, setSelectedFee] = useState(null);
  const [isDeleteDocumentModalOpen, setIsDeleteDocumentModalOpen] = useState(false);
  const [deleteDocumentModalSource, setDeleteDocumentModalSource] = useState("");
  const [showStudentInfo, setShowStudentInfo] = useState(false);
  const [feeStatusList, setFeeStatusList] = useState([]);
  const [newStatus, setNewStatus] = useState({});
  const [oldValue, setOldValue] = useState({});
  const [isRequiredDocumentModalOpen, setIsRequiredDocumentModalOpen] = useState(false);
  const [isValueModalOpen, setIsValueModalOpen] = useState(false);

  // Abre o modal de exclusão de documento
  const handleOpenDeleteDocumentModal = (source, fee) => {
    setSelectedFee(fee);
    setDeleteDocumentModalSource(source);
    setIsDeleteDocumentModalOpen(true);
  };

  const handleEdit = (fee) => {
    setMonthlyFee(fee);
    setShowEditModal(true);
  };

  const handlePageChange = async (page) => {
    setSelectedPage(page);
    search(page);
  }

  const handleSearch = async () => {
    if (searchQuery) {
      await search(selectedPage);
    } else {
      setShowTable(false);
      setStudentInfo(null);
    }
  };

  const search = async (_selectedPage) => {
    try {
      loadingHandler(true);
      let feeData;
      let showStudentInfo;

      if (searchQuery && !isEmpty(searchQuery)) {
        showStudentInfo = isNumber(searchQuery);
        feeData = showStudentInfo ?
          await Fees.getByRA(searchQuery, _selectedPage - 1) :
          await Fees.getByName(searchQuery, _selectedPage - 1);
      } else {
        feeData = await Fees.getAll(_selectedPage - 1);
        showStudentInfo = false;
      }

      setShowStudentInfo(showStudentInfo);

      if (!feeData || feeData?.total === 0) {
        setShowTable(false);
        setStudentInfo(null);
        toast(toastInfo.notFound);
      } else {
        setMonthlyFees(feeData);
        if (!showStudentInfo) {
          setStudentInfo(null);
        } else {
          setStudentInfo(`${feeData?.data[0]?.RA} - ${feeData?.data[0]?.NOME}`);
        }
        setShowTable(true);
      }
    } catch (error) {
      console.error("Erro ao buscar dados do aluno:", error);
      toast(toastError.notFound);
    }
    finally {
      loadingHandler(false);
    }
  }

  const handleUpload = (fee) => {
    setMonthlyFee(fee);
    setShowUploadModal(true);
  };

  const handleOnchangeEdit = (field, value) => {
    const formatValue = field === "VENCTO" ? formatToBackendDate(value) : value;
    setMonthlyFee({
      ...monthlyFee,
      [field]: formatValue,
    });
  };

  const handleGetFile = async (fileId) => {
    try {
      const fileUrl = await Fees.getDocument(fileId);
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmEdit = async () => {
    try {
      let updatedFee = {
        VALOR: monthlyFee.VALOR,
        VENCTO: monthlyFee.VENCTO
      };

      updatedFee = await Fees.updateData(monthlyFee.ID, updatedFee);

      setMonthlyFees({
        total: monthlyFees.total,
        data: pathOr([], ["data"], monthlyFees).map((item) =>
          item.ID === updatedFee.ID ? updatedFee : item
        ),
      });

      toast(toastSuccess.monthlyFeeUpdated);
    } catch (error) {
      console.error(
        "Erro ao atualizar mensalidade:",
        error.response?.data || error.message
      );
      toast(toastError.updateMonthlyFeeError(error));
    } finally {
      setShowEditModal(false);
      setMonthlyFee({});
    }
  };

  const handleConfirmUpdateValue = async (newValue) => {
    updateFeeStatus(selectedFee, newStatus, newValue)
  }

  const handleDeleteDoc = async (fee) => {
    handleOpenDeleteDocumentModal("deleteDocument", fee);
  };

  // Exclui comprovante
  const deleteDocument = async (fee) => {
    try {
      let updatedFee = await Fees.updateData(fee.ID, { upload_file_id: null });

      if (updatedFee) {
        setMonthlyFees({
          total: monthlyFees.total,
          data: pathOr([], ["data"], monthlyFees).map((item) =>
            item.ID === updatedFee.ID ? updatedFee : item
          ),
        });
      }
      toast(toastSuccess.updateFeeStatus(updatedFee));
    } catch (error) {
      toast(toastError.updateMonthlyFee(error));
      console.error(
        "Erro ao atualizar SYS_STATUS:",
        error.response?.data || error.message
      );
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileExtension = `.${selectedFile.name.split(".").pop()}`;
      if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
        toast(toastError.fileError);
        setFile(null);
        return;
      }
      setFile(selectedFile);
    }
  };

  const handleFileUpload = async (fee) => {
    if (!file) {
      toast(toastError.emptyFile);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      setShowUploadModal(false);
      loadingHandler(true);
      const responseUpload = await Fees.saveDocument(formData);
      if (responseUpload) {
        fee.upload_file_id = responseUpload.fileId;

        const feeData = { upload_file_id: responseUpload.fileId };
        await Fees.updateData(monthlyFee.ID, feeData);

        setMonthlyFees({
          total: monthlyFees.total,
          data: pathOr([], ["data"], monthlyFees).map((item) =>
            item.ID === fee.ID ? fee : item
          ),
        });

        toast(toastSuccess.upload(file.name));
      } else {
        toast(toastError.notFoundMonthlyFee);
        return;
      }
    } catch (error) {
      toast(toastError.upload(error));
    } finally {
      setMonthlyFee({});
      setFile(null);
      setShowUploadModal(false);
      loadingHandler(false);
    }
  };

  // Permite excluir o documento
  const handleDeleteDocument = async () => {
    setIsDeleteDocumentModalOpen(false);

    if (deleteDocumentModalSource === "updateFeeStatus") {
      await preUpdateFeeStatus(selectedFee, newStatus);
    }

    if (deleteDocumentModalSource === "deleteDocument") {
      await deleteDocument(selectedFee);
    }
  };

  const validateLabelStatus = (fee) => {
    if (fee.validated_by === 'conciliado') {
      return { fee, status: 2 };
    } else if (fee.updated_by !== null && fee.financ_status === null) {
      if (fee.SYS_STATUS === false) {
        return { fee, status: null };
      } else {
        return { fee, status: 2 };
      }
    }
    else if (fee.updated_by === null && fee.financ_status === null) {
      return { fee, status: 2 };
    }
    else {
      return { fee, status: (fee.financ_status ? 1 : 0) };
    }
  };

  const handleUpdateFeeStatus = async (fee, fee_status) => {
    setNewStatus(fee_status);

    if (fee_status.sys_status && fee.upload_file_id) {
      handleOpenDeleteDocumentModal("updateFeeStatus", fee);
    } else if (!fee_status.sys_status && !fee.upload_file_id && !(localStorage.getItem('profile_canValidate') === 'true')) {
      setIsRequiredDocumentModalOpen(true);
    } else {
      await preUpdateFeeStatus(fee, fee_status);
    }
  };

  const preUpdateFeeStatus = async (fee, fee_status) => {
    setOldValue(fee.VALOR);
    setSelectedFee(fee);
    if (fee_status.sys_status === false) {
      setIsValueModalOpen(true);
    } else {
      await updateFeeStatus(fee, fee_status);
    }
  }

  const updateFeeStatus = async (fee, fee_status, new_value) => {
    try {
      let updatedFee = {
        SYS_STATUS: fee_status.sys_status,
        fee_status: fee_status.id,
        DISABLED: fee_status.disabled
      };

      if (new_value) {
        updatedFee["VALOR"] = new_value;
      }

      loadingHandler(true);
      updatedFee = await Fees.updateData(fee.ID, updatedFee);
      loadingHandler(false);

      fee.SYS_STATUS = fee_status.sys_status;
      fee.fee_status = fee_status.id;

      if (updatedFee) {
        setMonthlyFees({
          total: monthlyFees.total,
          data: pathOr([], ["data"], monthlyFees).map((item) =>
            item.ID === updatedFee.ID ? updatedFee : item
          ),
        });
      }
      toast(toastSuccess.updateFeeStatus(updatedFee));
    } catch (error) {
      toast(toastError.updateMonthlyFee(error));
      console.error(
        "Erro ao atualizar SYS_STATUS:",
        error.response?.data || error.message
      );
    }
    finally {
      loadingHandler(false);
    }
  }

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.target.blur();
    }
  }

  const paginationCalulate = () => {
    const totalItems = monthlyFees.total;
    const itemsPerPage = 10;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    return totalPages;
  };

  const loadFeeStatus = async () => {
    try {
      //loadingHandler(true);
      let feeStatus = await Fees.getFeeStatus();
      setFeeStatusList(feeStatus);
    } catch (error) {
      console.error("Erro ao buscar os status de mensalidade:", error);
      toast(toastError.notFound);
    }
    finally {
      //loadingHandler(false);
    }
  }

  // Inicialização
  useEffect(() => {
    loadFeeStatus();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Heading
        as="h1"
        size="lg"
        mb="6"
        textAlign="center"
        bg={redColorScheme}
        bgClip="text"
      >
        Mensalidades
      </Heading>

      <form autoComplete="on" onSubmit={(e) => { e.preventDefault(); handleSearch(); }}>
        <Flex mb="4" justify="center" px={{ base: 6, lg: 0 }}>
          <Flex justify="center" width={{ base: "100%", lg: "50%" }}>
            <Input
              name="ra"
              placeholder="Pesquisar por RA ou nome"
              mr="2"
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyUp={handleKeyUp}
            />
            <IconButton
              icon={<SearchIcon color={invertedColorScheme} />}
              bg={redColorScheme}
              type="submit"
            />
          </Flex>
        </Flex>
      </form>

      {showTable && (
        <>
          <Flex width={"100%"}>
            <Flex justify="left" pl="6" width={"50%"}>
              {showStudentInfo && (
                <Text align={"left"} alignSelf={"flex-end"} fontWeight="bold" fontSize="sm">
                  {studentInfo}
                </Text>
              )}
            </Flex>
            <Flex justify="right" pr="6" alignSelf={"flex-end"} width={"50%"}>
              <Text align={"right"} fontSize="sm">
                Total de registros: {pathOr(0, ["total"], monthlyFees)}
              </Text>
            </Flex>
          </Flex>
          <Flex gap="4" mt="4" justify="center" direction={"column"}>
            <TableContainer width={"100%"} overflowX="auto">
              <Table variant="simple">
                <Thead bg={tableheaderbgc}>
                  <Tr>
                    {
                      !showStudentInfo && (
                        <>
                          <Th>RA</Th>
                          <Th>Nome</Th>
                        </>
                      )
                    }
                    <Th>Tipo</Th>
                    <Th>Curso</Th>
                    <Th>Tipo Pagamento</Th>
                    <Th>Valor</Th>
                    <Th>Parcela</Th>
                    <Th>Vencimento</Th>
                    <Th textAlign="center">Ações</Th>
                    <Th textAlign="center">Valid. Financeira</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {monthlyFees.data.map((fee) => (
                    <Tr key={fee.ID}>
                      {
                        !showStudentInfo && (
                          <>
                            <Td>{fee.RA || "N/A"}</Td>
                            <Td>{fee.NOME || "N/A"}</Td>
                          </>
                        )
                      }
                      <Td>{fee.TIPO_DEB || "N/A"}</Td>
                      <Td>{fee.CURSO || "N/A"}</Td>
                      <Td>{fee.TIPO_PAG || "N/A"}</Td>
                      <Td>{fee.VALOR || "N/A"}</Td>
                      <Td>{fee.PARC || "N/A"}</Td>
                      <Td>{fee.VENCTO || "N/A"}</Td>
                      <Td>
                        <Flex justify="center">
                          <Button
                            size="sm"
                            mr={2}
                            width={"80px"}
                            onClick={() => handleEdit(fee)}
                            leftIcon={<EditIcon />}
                            colorScheme="yellow"
                            isDisabled={localStorage.getItem("profile_canEdit") !== "true" || (fee.SYS_STATUS === false || fee.SYS_STATUS === null) || fee.validated_by === 'conciliado'}
                          >
                            Editar
                          </Button>
                          {fee.upload_file_id && (
                            <>
                              <Button
                                width={fee.financ_status !== true ? "120px" : "150px"}
                                size="sm"
                                colorScheme="blue"
                                onClick={() => handleGetFile(fee.upload_file_id)}
                                leftIcon={<FaEye />}
                                borderRightRadius={fee.financ_status !== true ? 0 : 6}
                                mr={fee.financ_status !== true ? 0 : 2}
                              >
                                Ver comprov.
                              </Button>
                              {
                                fee.financ_status !== true && (<Button
                                  width={"20px"}
                                  mr={2}
                                  size="sm"
                                  colorScheme="red"
                                  borderLeftRadius={0}
                                  onClick={() => handleDeleteDoc(fee)}
                                >
                                  <DeleteIcon />
                                </Button>)
                              }
                            </>
                          )}
                          {!fee.upload_file_id && (
                            <Button
                              width={"150px"}
                              size="sm"
                              mr={2}
                              colorScheme="blue"
                              onClick={() => handleUpload(fee)}
                              leftIcon={<FaUpload />}
                              isDisabled={localStorage.getItem("profile_canEdit") !== "true" || fee.financ_status === true || fee.validated_by === 'conciliado'}
                            >
                              Enviar comprov.
                            </Button>
                          )}
                          <StatusButton
                            fee={fee}
                            statusList={feeStatusList}
                            onUpdateStatus={handleUpdateFeeStatus}
                          />
                        </Flex>
                      </Td>
                      <Td>
                        <StatusLabel
                          fee={fee}
                          status={validateLabelStatus(fee)}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
          {paginationCalulate() > 1 && (
            <Flex mt="8" mb="4" justify="center">
              <ResponsivePagination
                current={selectedPage}
                total={paginationCalulate()}
                onPageChange={handlePageChange}
                maxWidth={500}
              />
            </Flex>
          )}
        </>
      )}

      <EditModal
        showModal={showEditModal}
        onChange={handleOnchangeEdit}
        close={() => setShowEditModal(false)}
        save={handleConfirmEdit}
        data={monthlyFee}
        currentPage={selectedPage}
      />

      <UploadModal
        showModal={showUploadModal}
        close={() => {
          setShowUploadModal(false);
          setFile(null);
          setMonthlyFee({});
        }}
        onChange={handleFileChange}
        save={() => handleFileUpload(monthlyFee)}
        data={file}
      />

      <DeleteDocumentModal
        isOpen={isDeleteDocumentModalOpen}
        onClose={() => setIsDeleteDocumentModalOpen(false)}
        onConfirm={handleDeleteDocument}
        source={deleteDocumentModalSource}
        newStatus={newStatus?.description}
      />

      <DocumentRequiredModal
        isOpen={isRequiredDocumentModalOpen}
        onClose={() => setIsRequiredDocumentModalOpen(false)}
        newStatus={newStatus?.description}
      />

      <ValueModal
        showModal={isValueModalOpen}
        close={() => setIsValueModalOpen(false)}
        oldValue={oldValue}
        save={handleConfirmUpdateValue}
      />
    </Box>
  );
}

export default FeesForm;