import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { pathOr } from "ramda";
import { formatToInputDate } from "utils";

function EditModal({
  showModal,
  onChange,
  close,
  save,
  data,
}) {
  return (
    <Modal isOpen={showModal} onClose={close}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar Mensalidade</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Valor</FormLabel>
            <Input
              type="number"
              value={pathOr('', ['VALOR'], data)}
              onChange={(e) => onChange('VALOR', e.target.value)}
              size="sm"
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Data de Vencimento</FormLabel>
            <Input
              type="date"
              value={formatToInputDate(pathOr('', ['VENCTO'], data))}
              onChange={(e) => onChange('VENCTO', e.target.value)}
              size="sm"
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={save} size={"sm"}>
            Confirmar Edição
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default EditModal;