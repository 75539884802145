const formatToInputDate = (date) => {
  const [day, month, year] = date.split("/");
  return `${year}-${month}-${day}`;
};

const formatToBackendDate = (date) => {
  const [year, month, day] = date.split("-");
  return `${day}/${month}/${year}`;
};

const isNumber = (input) => {
  return /^\d+$/.test(input);
}

export {
  formatToBackendDate,
  formatToInputDate,
  isNumber,
}