import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

import { useState } from "react";

function ValueModal({
  showModal,
  close,
  oldValue,
  save
}) {
  const [newValue, setNewValue] = useState(null);

  const handleChange = (value) => {
    setNewValue(value);
  };

  const handleSave = () => {
    save(newValue || oldValue);
    setNewValue(null);
    close();
  };

  return (
    <Modal isOpen={showModal} onClose={close}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Informar valor pago</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Valor pago</FormLabel>
            <Input
              type="number"
              defaultValue={newValue || oldValue}
              onChange={(e) => handleChange(e.target.value)}
              size="sm"
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleSave} size={"sm"}>
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ValueModal;