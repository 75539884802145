import {
  Flex,
  Text,
  Tooltip,
  Box,
  useColorModeValue
} from "@chakra-ui/react";
import { FaCheckCircle, FaTimesCircle, FaClock, FaBan } from "react-icons/fa";

const StatusLabel = ({ fee, status }) => {
  const redColorScheme = useColorModeValue("#e53e3e", "#feb2b2");
  const greenColorScheme = useColorModeValue("#38a169", "#9ae6b4");
  const yellowColorScheme = useColorModeValue("#ecc94b", "#faf089");
  const grayColorScheme = useColorModeValue("#718096", "#474c56");
  const invertedColorScheme = useColorModeValue("#eeeff0", "#1a202c");

  const getStatusProperties = (status) => {
    switch (status.status) {
      case 2:
        return { color: grayColorScheme, icon: <FaBan color={grayColorScheme} />, text: "N/A", fontWeight: "normal" };
      case 1:
        return { color: greenColorScheme, icon: <FaCheckCircle color={greenColorScheme} />, text: "Aprovado", fontWeight: "bold" };
      case 0:
        return { color: redColorScheme, icon: <FaTimesCircle color={redColorScheme} />, text: "Recusado", fontWeight: "bold" };
      default:
        return { color: yellowColorScheme, icon: <FaClock color={yellowColorScheme} />, text: "Em análise", fontWeight: "bold" };
    }
  };

  const { color, icon, text, fontWeight } = getStatusProperties(status);

  return (
    <Flex cursor="default" alignItems="end" justifyContent="center">
      <Tooltip
        hasArrow
        placement="top"
        bg={redColorScheme}
        color={invertedColorScheme}
        boxShadow="lg"
        borderRadius="lg"
        fontSize="md"
        p="3"
        label={
          <Box>
            <Text fontWeight="bold">Motivo:</Text>
            <Text>{fee.financ_obs || "Sem justificativa"}</Text>
          </Box>
        }
        isDisabled={fee.financ_status !== false}
      >
        <Text fontSize="sm" lineHeight="12px" fontWeight={fontWeight} color={color}><i style={{ float: "left", paddingRight: "4px" }}>{icon}</i>{text}</Text>
      </Tooltip>
    </Flex>
  );
};

export default StatusLabel;
