import axios from "axios";

class Profiles {
  // Configuração do cabeçalho para requisições 
  getDefaultHeader() {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token não está definido.");
    }

    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
  }

  // Busca perfil pelo nome
  async getByName(name) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ELDORADO_API_URL}/profile/${name}`,
        this.getDefaultHeader()
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao buscar perfil pelo nome:",
        error.response?.data || error.message
      );
      throw error;
    }
  }

  // Busca perfil pelo nome ou parte dele
  async findByName(name, pageIndex = 0, token = null) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ELDORADO_API_URL}/profile/find/${name}?pageIndex=${pageIndex}&pageSize=10`,
        this.getDefaultHeader(token)
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao buscar perfil pelo e-mail:",
        error.response?.data || error.message
      );
      throw error;
    }
  }

  // Busca todos os perfis
  async getAll(pageIndex = 0) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ELDORADO_API_URL}/profile?pageIndex=${pageIndex}&pageSize=10`,
        this.getDefaultHeader()
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao buscar perfis:",
        error.response?.data || error.message
      );
      throw error;
    }
  }

  // Atualiza perfil
  async updateData(name, updatedData) {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_ELDORADO_API_URL}/profile/update/${name}`,
        updatedData,
        this.getDefaultHeader()
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao atualizar perfil:",
        error.response?.data || error.message
      );
      throw error;
    }
  }

  // Adiciona perfil
  async add(updatedData) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ELDORADO_API_URL}/profile/add`,
        updatedData,
        this.getDefaultHeader()
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao adicionar perfil:",
        error.response?.data || error.message
      );
      throw error;
    }
  }
}

const profile = new Profiles();
export default profile;
