import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Text,
} from "@chakra-ui/react";

const ImportResultModal = ({ isOpen, onClose, importResult }) => {
  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Resultado da importação
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>Total de registros inseridos: {importResult?.success || 0}</Text>
            <Text>Total de erros: {importResult?.errors?.length || 0}</Text>
            {importResult?.errors?.length > 0 && importResult.errors.map((error) => (
              <>
                <br />
                <Text>{error.includes('temp_student_pk') ? `${error.substring(0,9)}Registro já foi importado anteriormente.` : error}</Text>
              </>
            ))}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose} size={"sm"}>
              OK
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ImportResultModal;
