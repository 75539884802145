import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text
} from "@chakra-ui/react";
import { FaCheckCircle, FaExclamationTriangle, FaFileUpload } from "react-icons/fa";

const StatusButton = ({ fee, statusList, onUpdateStatus }) => {
  const getStatusProperties = () => {
    let sys_status;
    let text;
    let disabled = fee.financ_status === true;

    if (fee.fee_status != null && statusList) {
      const feeStatus = statusList?.data.find(({ id }) => id === fee.fee_status);
      sys_status = feeStatus.sys_status;
      text = feeStatus.description;
    } else {
      sys_status = fee.SYS_STATUS;
      text = fee.SYS_STATUS ? "Pendente" : "Pago";
    }

    if (fee.validated_by === 'conciliado') {
      return { color: "orange", icon: <FaFileUpload />, text: "Conciliado", isDisabled: true }
    }

    switch (sys_status) {
      case true:
        return { color: "red", icon: <FaExclamationTriangle />, text: text, isDisabled: disabled }
      default:
      case false:
        return { color: "green", icon: <FaCheckCircle />, text: text, isDisabled: disabled }
    }
  };

  const { color, icon, text, isDisabled } = getStatusProperties();

  return (
    <Menu>
      <MenuButton as={Button} colorScheme={color} width="180px" size="sm" isDisabled={isDisabled}>
        <Flex align="center" justify="center" gap="2">
          {icon}
          <Text>{text}</Text>
        </Flex>
      </MenuButton>
      <MenuList>
        {statusList.data.map((status) => (
          <MenuItem
            key={status.id}
            icon={
              {
                false: <FaCheckCircle color="green" />,
                true: <FaExclamationTriangle color="red" />
              }[status.sys_status]
            }
            onClick={() => onUpdateStatus(fee, status)}
            fontSize="sm"
          >
            {status.description}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default StatusButton;
