import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, role }) => {
  const isAuthenticated = localStorage.getItem("token");
  const requiredRole = localStorage.getItem(`profile_${role}`) === "true";

  if (isAuthenticated && requiredRole) {
    return <>{children}</>;
  }

  if (localStorage.getItem("profile_canEdit") === "true") {
    return <Navigate to="/mensalidades" />;
  }

  if (localStorage.getItem("profile_canValidate") === "true") {
    return <Navigate to="/validacao" />;
  }

  if (localStorage.getItem("profile_canManage") === "true") {
    return <Navigate to="/usuarios" />;
  }

  if (localStorage.getItem("profile_canImport") === "true") {
    return <Navigate to="/importacao" />;
  }

  return <Navigate to="/" />;
};

export default ProtectedRoute;
