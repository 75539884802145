import axios from "axios";

class Import {
  // Configuração do cabeçalho para requisições
  getHeader() {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token não está definido.");
    }
    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
  }

  // Configuração do cabeçalho para requisições
  getUploadHeader() {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token não está definido.");
    }
    return {
      headers: {
        "Content-Type": "multipart/form-data",
        timeout: 60000,
        Authorization: `Bearer ${token}`,
      },
    };
  }

  // Importa arquivo
  async import(file, target) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ELDORADO_API_URL}/import/${target}`,
        file,
        this.getUploadHeader()
      );

      return response.data;
    } catch (error) {
      console.error(
        "Erro ao enviar arquivo:",
        error.response?.data || error.message
      );
      throw error;
    }
  }

  //Retorna os nomes dos campos da tabela de importação
  async getTemplates() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ELDORADO_API_URL}/import/getTemplates`,
        this.getHeader()
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao obter informações:",
        error.response?.data || error.message
      );
      throw error;
    }
  }

  //Retorna o CSV do da última importação
  async getLast(target) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ELDORADO_API_URL}/import/getLastImport/${target}`,
        this.getHeader()
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao obter informações:",
        error.response?.data || error.message
      );
      throw error;
    }
  }
}

const import_ = new Import();
export default import_;
