import { allowedExtensions } from "components/Forms/FeesForm/UploadModal"
import { pathOr } from "ramda"

const toastSuccess = {
  upload: (fileName) => ({
    title: "Arquivo enviado com sucesso!",
    description: `O arquivo ${fileName} foi processado.`,
    status: "success",
    duration: 3000,
    isClosable: true,
  }),
  found: {
    title: "Dados encontrados com sucesso.",
    status: "success",
    duration: 3000,
    isClosable: true,
  },
  monthlyFeeUpdated: {
    title: "Mensalidade atualizada com sucesso.",
    status: "success",
    duration: 3000,
    isClosable: true,
  },
  updateFeeStatus: (fee) => ({
    title: `${pathOr("", ['TIPO_PAG'], fee)} ${pathOr("", ['SYS_STATUS'], fee)
      ? "registrada como pendente"
      : "Paga"
      } com sucesso!`,
    status: "success",
    duration: 3000,
    isClosable: true,
  }),
  updateFeeValidation: (fee) => ({
    title: `${pathOr("", ['TIPO_PAG'], fee)} ${fee['financ_status'] == null
      ? "registrada como 'em análise'"
      : (fee['financ_status'] ? "Aprovado" : "Recusado")
      } com sucesso!`,
    status: "success",
    duration: 3000,
    isClosable: true,
  }),
  userUpdated: {
    title: "Usuário atualizado com sucesso.",
    status: "success",
    duration: 3000,
    isClosable: true,
  },
  userAdded: {
    title: "Usuário adicionado com sucesso.",
    status: "success",
    duration: 3000,
    isClosable: true,
  },
  profileUpdated: {
    title: "Perfil atualizado com sucesso.",
    status: "success",
    duration: 3000,
    isClosable: true,
  },
  profileAdded: {
    title: "Perfil criado com sucesso.",
    status: "success",
    duration: 3000,
    isClosable: true,
  },
  importSuccess: {
    title: "Arquivo importado com sucesso.",
    status: "success",
    duration: 3000,
    isClosable: true,
  }
}



const toastInfo = {
  notFound: {
    title: "Nenhum dado encontrado para o RA informado.",
    status: "warning",
    duration: 3000,
    isClosable: true,
  },
  userNotFound: {
    title: "Nenhum usuário encontrado para o E-mail informado.",
    status: "warning",
    duration: 3000,
    isClosable: true,
  },
  profileNotFound: {
    title: "Nenhum perfil foi encontrado.",
    status: "warning",
    duration: 3000,
    isClosable: true,
  }
}

const toastError = {
  notFound: {
    title: "Erro ao realizar a consulta.",
    description: "Verifique o RA e tente novamente.",
    status: "error",
    duration: 3000,
    isClosable: true,
  },
  notFoundMonthlyFee: {
    title: "Mensalidade não encontrada.",
    status: "error",
    duration: 3000,
    isClosable: true,
  },
  userNotFound: {
    title: "Erro ao realizar a consulta.",
    description: "Verifique o E-mail e tente novamente.",
    status: "error",
    duration: 3000,
    isClosable: true,
  },
  profileNotFound: {
    title: "Erro ao realizar a consulta.",
    status: "error",
    duration: 3000,
    isClosable: true,
  },
  upload: (error) => ({
    title: "Falha ao enviar arquivo.",
    description: pathOr("Falha ao fazer upload do arquivo.", ['response', 'data', 'message'], error),
    status: "error",
    duration: 3000,
    isClosable: true,
  }),
  emptyFile: {
    title: "Nenhum arquivo selecionado.",
    description: "Por favor, selecione um arquivo para enviar.",
    status: "warning",
    duration: 3000,
    isClosable: true,
  },
  updateMonthlyFee: (error) => ({
    title: "Erro ao atualizar status da mensalidade.",
    description: pathOr("Tente novamente mais tarde.", ['response', 'data', 'message'], error),
    status: "error",
    duration: 3000,
    isClosable: true,
  }),
  updateValidation: (error) => ({
    title: "Erro ao atualizar validação financeira.",
    description: pathOr("Tente novamente mais tarde.", ['response', 'data', 'message'], error),
    status: "error",
    duration: 3000,
    isClosable: true,
  }),
  updateMonthlyFeeError: (error) => ({
    title: "Erro ao atualizar mensalidade.",
    description: pathOr("Tente novamente mais tarde.", ['response', 'data', 'message'], error),
    status: "error",
    duration: 3000,
    isClosable: true,
  }),
  fileError: {
    title: "Formato de arquivo não suportado.",
    description: `Por favor, selecione um arquivo com uma das extensões permitidas: ${allowedExtensions.join(
      ", "
    )}`,
    status: "error",
    duration: 4000,
    isClosable: true,
  },
  updateUserError: (error) => ({
    title: "Erro ao atualizar usuário.",
    description: pathOr("Tente novamente mais tarde.", ['response', 'data', 'message'], error),
    status: "error",
    duration: 3000,
    isClosable: true,
  }),
  addUserError: (error) => ({
    title: "Erro ao adicionar usuário.",
    description: pathOr("Tente novamente mais tarde.", ['response', 'data', 'message'], error),
    status: "error",
    duration: 3000,
    isClosable: true,
  }),
  updateProfileError: (error) => ({
    title: "Erro ao atualizar perfil.",
    description: pathOr("Tente novamente mais tarde.", ['response', 'data', 'message'], error),
    status: "error",
    duration: 3000,
    isClosable: true,
  }),
  addProfileError: (error) => ({
    title: "Erro ao criar perfil.",
    description: pathOr("Tente novamente mais tarde.", ['response', 'data', 'message'], error),
    status: "error",
    duration: 3000,
    isClosable: true,
  }),
  importError: (error) => ({
    title: "Erro ao importar arquivo.",
    description: pathOr("Tente novamente mais tarde.", ['response', 'data', 'message'], error),
    status: "error",
    duration: 3000,
    isClosable: true,
  }),
  importInfoError: (error) => ({
    title: "Erro ao obter dados da importação.",
    description: pathOr("Tente novamente mais tarde.", ['response', 'data', 'message'], error),
    status: "error",
    duration: 3000,
    isClosable: true,
  }),
  templateError: (error) => ({
    title: "Erro ao gerar template.",
    description: pathOr("Tente novamente mais tarde.", ['response', 'data', 'message'], error),
    status: "error",
    duration: 3000,
    isClosable: true,
  })
}

export {
  toastError,
  toastSuccess,
  toastInfo,
}